export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBD2koAP5W2alzrQ2_SrZQE2QpHmJpESp0",
    authDomain: "tvtaboanense-230d2.firebaseapp.com",
    databaseURL: "https://tvtaboanense-230d2.firebaseio.com",
    projectId: "tvtaboanense-230d2",
    storageBucket: "",
    messagingSenderId: "426201767301"
  }
};